const intro = () => {

	// Declare vars
	const siteLogo = document.querySelector('.site-logo');
	const iconList = document.querySelector('.icon-list');
	//const modal = document.querySelector('dialog');
	let logoPush = document.querySelector('.sl-2');
	let iconListItem = document.querySelectorAll('.icon-list > li:last-child')[0];
	let eventLogoEnd = new Event('logoend');	
		
	// init polyfill
	//dialogPolyfill.registerDialog(modal);		
		
		
	// Show logo
	setTimeout(function(){
		siteLogo.classList.add('visible');
	},1000);
		
		
	// Once logo is done, show icon list
	siteLogo.addEventListener('transitionend', function(){
		iconList.classList.add('visible');			
	});
	
		
	// Once icon list is done, init logo push
	iconListItem.addEventListener('transitionend', function(){
		if(!logoPush.classList.contains('active')) {			
			logoPush.classList.add('active');
			
			document.getElementsByTagName('body')[0].classList.add('is-loaded');
			
			setTimeout(function(){
				logoPush.dispatchEvent(eventLogoEnd);			
			}, 4000);
		} else {
			return;
		}
	});

}

export { intro }
